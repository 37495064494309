import { css } from '@emotion/core';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { mainColor } from '../../constants/colors';
import { Desktop, Tablet } from '../../constants/responsive';
import { useAppContext } from '../../context/AppContext';
import Check from '../../svgs/check.svg';

const CareerInfoUploading = ({
  showUploading = false,
  setShowUploading = () => {},
}) => {
  const intl = useIntl();
  return (
    <div
      css={css`
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: ${showUploading ? 'flex' : 'none'};
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.2);
        z-index: 9999;
      `}
    >
      <div
        css={css`
          background: ${mainColor};
          border-radius: 12px;
          position: relative;
          display: flex;
          overflow: hidden;
          width: 90%;
          max-width: 340px;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: white;
          text-align: center;
          padding: 20px;
          height: 300px;
          ${Tablet} {
            max-width: none;
            padding: 20px;
            width: 340px;
          }
          ${Desktop} {
            padding: 40px;
            width: 440px;
            height: 360px;
          }
        `}
      >
        <h2
          css={css`
            display: block;
            margin-bottom: 16px;
          `}
        >
          {
            {
              en: 'The file is being uploaded, please wait',
              'zh-cn': '上传中，请稍等',
              'zh-hk': '上傳中，請稍等',
            }[intl.locale]
          }
        </h2>

        <div
          css={css`
            width: 30px;
            height: 30px;
            cursor: pointer;
            position: absolute;
            right: 20px;
            top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
          onClick={() => {
            setShowUploading(false);
          }}
        >
          {[1, -1].map((i) => {
            return (
              <div
                key={i}
                css={css`
                  background: white;
                  width: 30px;
                  height: 2px;
                  position: absolute;
                  transform: ${`rotate(${i * 45}deg)`};
                `}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CareerInfoUploading;
